import _ from "lodash";
import {
  type EntityId,
  type Media,
  type Transcript,  
} from "~/types/types";
import moment from 'moment';
import { generateEntityId } from "~/types/helpers";
import { API } from "./util";

const apiRequest = new API<Transcript>("transcripts");

export const useTranscriptStore = defineStore("transcriptStore", {
  state: () => ({
    transcripts: [] as Transcript[],
  }),
  getters: {
    getTranscript: (state) => (_id: EntityId) => {
      const item = state.transcripts.find((x: Transcript) => x._id === _id);
      if(!item) throw new Error(`Transcript not found: ${_id }`);
      return item;
    },
    getTranscripts: (state) => (topicId: EntityId) => state.transcripts.filter((x: Transcript) => x.topicId === topicId),
  },
  actions: {
    buildTranscriptFromMedia(item: Media) {
      const newTranscript: Transcript = {
        _id: generateEntityId(),
        status: "pending",
        source: item.url,
        topicId: item.topicId,
        slug: `${item.name} ${moment(item.created).format("YYYY-MM-DD hh:mm a")}`,
        text: "",
        created: new Date(),
      }
      return newTranscript;
    },
    updateStore(item: Transcript) {
      this.transcripts = this.transcripts.map((x: Transcript) => {
        if(x._id === item._id){
          return item;
        } 
        return x;
      })
    },
    async addTranscript(item: Transcript) {
      try{ 
        this.transcripts.push(item);
        const created = await apiRequest.post(item);  
        this.updateStore(created);
        return created;    
      }
      catch(err){
        throw err;
      }
    },
    async deleteTranscript(item: Transcript) {
      this.transcripts = this.transcripts.filter((x: Transcript) => x._id !== item._id);      
      await apiRequest.delete(item._id);
    },
    async updateTranscript(item: Transcript) {
      this.updateStore(item);
      await apiRequest.put(item._id, item);
      return item;
    },
    async fetchData() {
      const transcripts = (await apiRequest.get());
      this.transcripts = transcripts;      
    },
  },
});
