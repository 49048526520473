import _ from "lodash";
import type {
  Content,
  EntityId,
  Org,  
} from "~/types/types";
import {API} from "./util";

const apiRequest = new API<Content>("content");

export const useContentStore = defineStore("contentStore", {
  state: () => ({
    content: [] as Content[],
  }),
  getters: {
    getContent: (state) => (contentId: EntityId) => {
      const content = state.content.find((x: Content) => x._id === contentId);
      if(!content) throw new Error(`content not found: ${contentId}`);
      return content;
    },
    getContentItems: (state) => (topicId: EntityId) => state.content.filter((x: Content) => x.topicId===topicId),
  },
  actions: {
    async addContentItem(item: Content) {
      this.content.push(item);
      const created = await apiRequest.post(item);      
      this.content = this.content.map((x: Content) => (x._id === created._id ? created : x));
    },
    async updateContentItem(item: Content){
      this.content = this.content.map((x: Content) => x._id === item._id ? item : x);
      await apiRequest.put(item._id, item);      
    },
    async deleteContent(item: Content) {
      this.content = this.content.filter((x: Content) => x._id !== item._id);
      await apiRequest.delete(item._id);
    },
    async fetchData() {
      this.content = await apiRequest.get();      
    },
  },
});
