import _ from "lodash";
import type {
  EntityId,
  Publication,  
} from "~/types/types";
import { API } from "./util";

const apiRequest = new API<Publication>("publications");

export const usePublicationStore = defineStore("publicationStore", {
  state: () => ({
    publications: [] as Publication[],
  }),
  getters: {
    getPublication: (state) => (_id: EntityId) => {
      const item = state.publications.find((x: Publication) => x._id === _id);
      if(!item) throw new Error(`Publication not found: ${_id }`);
      return item;
    },
    getPublications: (state) => (topicId: EntityId) => state.publications.filter((x: Publication) => x.topicId === topicId),
  },
  actions: {
    async activatePublication(item: Publication) {
      const updatedItem = { ...item, status: "activating" };
      this.publications = this.publications.map((x: Publication) => (x._id === item._id ? updatedItem : x));
      await this.updatePublication(updatedItem);
    },
    async addPublication(item: Publication) {
      this.publications.push(item);
      await apiRequest.post(item);      
    },
    async deletePublication(item: Publication) {
      this.publications = this.publications.filter((x: Publication) => x._id !== item._id);      
      await apiRequest.delete(item._id);
    },
    async updatePublication(item: Publication) {
      this.publications = this.publications.map((x: Publication) => (x._id === item._id ? item : x));
      await apiRequest.put(item._id, item);
    },
    async fetchData() {
      const publications = (await apiRequest.get());
      this.publications = publications;      
    },
  },
});
