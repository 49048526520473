import type { EntityId } from "./types";
import ObjectID from "bson-objectid";

export function generateEntityId(): EntityId {
  return ObjectID().toHexString();
}

export function parseEntityId(id: string): EntityId {
  return ObjectID(id).toHexString();
}
