import _ from "lodash";
import type {
  EntityId,
  Media,
} from "~/types/types";
import { API } from "./util";

const apiRequest = new API<Media>("media");

export const useMediaStore = defineStore("mediaStore", {
  state: () => ({
    media: [] as Media[],
  }),
  getters: {
    getMedia: (state) => (mediaId: EntityId) => {
      const media = state.media.find((x: Media) => x._id === mediaId);
      return media;
    },
    getMediaItems: (state) => (topicId: EntityId) => state.media.filter((x: Media) => x.topicId===topicId),
  },
  actions: {  
    async updateMedia(item: Media) {
      this.media = this.media.map((x: Media) => (x._id === item._id ? item : x));
      await apiRequest.put(item._id, item);
    },
    async addMediaItem(media: Media) {
      this.media.push(media);
      const newMedia = await apiRequest.post(media);
      this.media = this.media.map((x: Media) => (x._id === media._id ? newMedia : x));
    },
    async deleteMedia(item: Media) {
      this.media = this.media.filter((x: Media) => x._id !== item._id);
      await apiRequest.delete(item._id);
    },    
    async fetchData() {
      const media = (await apiRequest.get());
      this.media = media;
    },
  },
});
