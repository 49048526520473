import _ from "lodash";
import {API} from "./util";
import type {
  Media,
  EntityId,
  Topic,
} from "~/types/types";

const apiRequest = new API<Topic>("topics");

export const useTopicStore = defineStore("topicStore", {
  state: () => ({
    topics: [] as Topic[],
  }),
  getters: {
    getTopic: (state) => (topicId: EntityId) => {
      const topic = state.topics.find((x: Topic) => x._id === topicId);
      if(!topic) throw new Error(`topic not found: ${topicId}`);
      return topic;
    },
    getTopics: (state) => (orgId: EntityId) => state.topics.filter((x: Topic) => x.orgId===orgId),
  },
  actions: {
    async addTopic(topic: Topic) {
      this.topics.push(topic);
      await apiRequest.post(topic);      
    },
    async deleteTopic(topic: Topic) {
      this.topics = this.topics.filter((x: Topic) => x._id !== topic._id);
      await apiRequest.delete(topic._id);
    },
    async updateTopic(item: Topic) {
      this.topics = this.topics.map((x: Topic) => (x._id === item._id ? item : x));
      await apiRequest.put(item._id, item);
    },
    async fetchData() {
      const topics = (await apiRequest.get());
      this.topics = topics;
    },
  },
});
