<template>
  <NuxtLayout name="main">
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { 
  useTopicStore, 
  useTranscriptStore, 
  useContentStore,
  useMediaStore,
  usePublicationStore,
  useConversationStore,
  useCreatorStore,
  useOrgStore,
} from "./store";

await callOnce(async () => {
  await Promise.all([
    useTranscriptStore().fetchData(),
    useTopicStore().fetchData(),
    useContentStore().fetchData(),
    useMediaStore().fetchData(),
    usePublicationStore().fetchData(),
    useConversationStore().fetchData(),
    useCreatorStore().fetchData(),
    useOrgStore().fetchData(),
  ]);  
});
</script>
