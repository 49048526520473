import _ from "lodash";
import type { Conversation, EntityId } from "~/types/types";
import { API } from "./util";

const apiRequest = new API<Conversation>("conversations");

export const useConversationStore = defineStore("conversationStore", {
  state: () => ({
    conversations: [] as Conversation[],
  }),
  getters: {
    getConversation: (state) => (conversationId: EntityId) => {
      const conversation = state.conversations.find(
        (x: Conversation) => x._id === conversationId
      );
      if (!conversation)
        throw new Error(`Conversation not found: ${conversationId}`);
      return conversation;
    },
    getConversations: (state) => (topicId: EntityId) =>
      state.conversations.filter((x: Conversation) => x.topicId === topicId),
  },
  actions: {
    async addConversation(item: Conversation) {
      this.conversations.push(item);
      const created = await apiRequest.post(item);
      this.conversations = this.conversations.map((x: Conversation) =>
        x._id === created._id ? created : x
      );
    },
    async deleteConversation(item: Conversation) {
      this.conversations = this.conversations.filter(
        (x: Conversation) => x._id !== item._id
      );
      await apiRequest.delete(item._id);
    },
    async updateConversation(item: Conversation) {
      this.conversations = this.conversations.map((x: Conversation) =>
        x._id === item._id ? item : x
      );
      await apiRequest.put(item._id, item);
    },
    async requestConversiveSession(item: Conversation) {
      item.status = "requesting";
      this.conversations = this.conversations.map((x: Conversation) =>
        x._id === item._id ? item : x
      );
      const updated = await apiRequest.put(item._id, item);
      this.conversations = this.conversations.map((x: Conversation) =>
        x._id === updated._id ? updated : x
      );      
    },
    async fetchData() {
      this.conversations = await apiRequest.get();
    },
  },
});
