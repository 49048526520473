import _ from "lodash";
import type {
  Creator,
  EntityId,  
} from "~/types/types";
import { API } from "./util";

const apiRequest = new API<Creator>("creators");

export const useCreatorStore = defineStore("creatorStore", {
  state: () => ({
    creators: [] as Creator[],
  }),
  getters: {
    getCreator: (state) => (creatorId: EntityId) => {
      const creator = state.creators.find((x: Creator) => x._id === creatorId);
      if(!creator) throw new Error(`Creator not found: ${creatorId}`);
      return creator;
    },
    getCreators: (state) => (orgId: EntityId) => state.creators.filter((x: Creator) => x.orgId===orgId),
  },
  actions: {
    async addCreator(creator: Creator) {
      this.creators.push(creator);
      await apiRequest.post(creator);      
    },
    async deleteCreator(creator: Creator) {
      this.creators = this.creators.filter((x: Creator) => x._id !== creator._id);
      await apiRequest.delete(creator._id);
    },
    async updateCreator(item: Creator) {
      this.creators = this.creators.map((x: Creator) => (x._id === item._id ? item : x));
      await apiRequest.put(item._id, item);
    },
    async fetchData() {
      const creators = (await apiRequest.get());
      this.creators = creators;
    },
  },
});
